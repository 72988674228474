const WEB_SOCKET_URL = "wss://smarthome-dashboard-back.weplto.co.kr/ws";

const connectWebSocketMonitoring = ({
  socket,
  page,
  itemsPerPage,
  setMonitoring,
  setMonitoringLoading,
  setPageLoading,
}) => {
  socket.current = new WebSocket(
    `${WEB_SOCKET_URL}/monitoring?page=${page}&items_per_page=${itemsPerPage}`
  );

  socket.current.onopen = () => {
    console.log("Monitor WebSocket 연결!");
    if (setMonitoringLoading) setMonitoringLoading(false);
  };
  socket.current.onmessage = (event) => {
    setMonitoring(JSON.parse(event.data));
    if (setPageLoading) setPageLoading(false);
  };
  socket.current.onerror = (error) => {
    // console.log(error);
    // setMonitoringLoading(false);
  };
  socket.current.onclose = () => {
    console.log("Monitor WebSocket Close");
  };
};

const connectWebSocketAlert = ({ socket, setAlerts }) => {
  socket.current = new WebSocket(`${WEB_SOCKET_URL}/alerts`);

  socket.current.onopen = () => {
    console.log("Alerts WebSocket 연결!");
  };
  socket.current.onmessage = (event) => {
    setAlerts(JSON.parse(event.data));
  };
  socket.current.onerror = () => {};
  socket.current.onclose = () => {
    console.log("Alerts WebSocket Close");
  };
};

export { connectWebSocketMonitoring, connectWebSocketAlert };
