import activeIcon from "../assets/active.svg";
import emptyIcon from "../assets/empty.svg";
import sleepIcon from "../assets/sleep.svg";

const useUserStatus = () => {
  const statusMap = {
    ACTIVE: {
      color: "active",
      icon: activeIcon,
      text: "활동감지",
    },
    AWAY: {
      color: "empty",
      icon: emptyIcon,
      text: "자리비움",
    },
    SLEEPING: {
      color: "sleep",
      icon: sleepIcon,
      text: "수면중",
    },
  };

  const getStatusColor = (user_status) => statusMap[user_status]?.color || "";
  const getStatusIcon = (user_status) =>
    statusMap[user_status]?.icon || emptyIcon;
  const getStatus = (user_status) => statusMap[user_status]?.text || "";

  return { getStatusColor, getStatusIcon, getStatus };
};

export default useUserStatus;
