import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { format, subDays } from "date-fns";

// Register the necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const ChartCard = ({ heart = [], respiration = [] }) => {
  const generateLast7Days = () => {
    const dates = [];
    const today = new Date();

    for (let i = 6; i >= 1; i--) {
      const previousDate = subDays(today, i);

      dates.push(format(previousDate, "dd"));
    }

    return dates;
  };

  const labels = generateLast7Days();

  const data = {
    labels: labels,
    datasets: [
      {
        label: "심박수",
        backgroundColor: "#ECAACE",
        data: heart,
      },
      {
        label: "호흡수",
        backgroundColor: "#73BFEA",
        data: respiration,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        display: false,
      },
      x: {
        stacked: false,
        type: "category",
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "생체 정보 기록",
        font: {
          size: 14,
          family: "Roboto, sans-serif",
          weight: 400,
        },
        color: "#000",
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "end",
        align: "end",
        color: "#333333",
        font: {
          weight: "400",
        },
        offset: -5,
      },
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default ChartCard;
