import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import closeIcon from "../../assets/close.svg";
import "../../style/alertModal.css";
import "../../style/modalLayout.css";
import AlertsCard from "../card/AlertsCard";
import { useMainDashboardContext } from "../../context/MainDashboardProvider";
import { getMonitoringSensorAlerts } from "../../api/monitoring";
import Pagination from "../Pagination";

Modal.setAppElement("#root");

const AlertsModal = ({ onClose, alertRoom }) => {
  const [roomAlerts, setRoomAlerts] = useState(null);
  const { refreshSocketAlert, refreshSocketMonitor } =
    useMainDashboardContext();
  const [page, setPage] = useState(1);

  useEffect(() => {
    document.body.classList.add("modal-open");
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getMonitoringSensorAlerts({
        sensorId: alertRoom.sensor_id,
        page,
      });
      setRoomAlerts(data.data);
    })();
  }, [page, alertRoom]);

  const closeHanler = () => {
    refreshSocketMonitor();
    refreshSocketAlert();
    onClose();
  };

  const customStyles = {
    content: {
      backgroundColor: "#fff",
      width: "460px",
      height: "fit-content",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: 0,
      padding: 0,
      border: "1px solid #131b45",
      boxSizing: "border-box",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      // height: "470px",
    },
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeHanler}
      contentLabel="Modal"
      style={customStyles}
    >
      <div style={{}}>
        {/* 헤더 */}
        <div className="modalHeader">
          <span>경고알림</span>
          <img
            src={closeIcon}
            alt="closeIcon"
            className="close-Icon"
            onClick={closeHanler}
          />
        </div>
        {/* 메인 */}
        <div className="modalMain">
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <span className="mainAddress">{alertRoom?.address}</span>
            <span>총 {roomAlerts?.total_count} 건</span>
          </div>
          <div className="alertMain">
            {roomAlerts?.data?.map((room, index) => {
              return <AlertsCard key={index} data={room} />;
            })}
          </div>
          <Pagination
            currentPage={roomAlerts?.current_page}
            totalPages={roomAlerts?.total_pages}
            onPageChange={setPage}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AlertsModal;
