import React, { createContext, useContext, useState } from "react";
import { localKeys } from "../constants/keys";

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const name = sessionStorage.getItem("userName");
  const storageAuth = Boolean(localKeys.passwordKey[name]);

  const [isAuthenticated, setIsAuthenticated] = useState(storageAuth);
  const [userName, setUserName] = useState(name);

  const login = (name) => {
    setIsAuthenticated(true);
    setUserName(name);
    sessionStorage.setItem("userName", name);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserName(null);
    sessionStorage.removeItem("userName");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userName, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
