import { MainDashboardProvider } from "../context/MainDashboardProvider";
import MainDashboard from "./MainDashboard";

function MainPage() {
  return (
    <MainDashboardProvider>
      <MainDashboard />
    </MainDashboardProvider>
  );
}

export default MainPage;
