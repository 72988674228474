import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  connectWebSocketAlert,
  connectWebSocketMonitoring,
} from "../api/websocket";

const MainDashboardContext = createContext(null);

const useMainDashboardContext = () => useContext(MainDashboardContext);

function MainDashboardProvider({ children }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(60);
  const [monitoring, setMonitoring] = useState({});
  const [monitoringLoading, setMonitoringLoading] = useState(true);
  const [alerts, setAlerts] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  const monitorRef = useRef(null);
  const alertRef = useRef(null);

  useEffect(() => {
    setPageLoading(true);
    connectWebSocketMonitoring({
      socket: monitorRef,
      page: currentPage,
      itemsPerPage: itemsPerPage,
      setMonitoring: setMonitoring,
      setMonitoringLoading: setMonitoringLoading,
      setPageLoading: setPageLoading,
    });

    connectWebSocketAlert({
      socket: alertRef,
      setAlerts: setAlerts,
    });

    return () => {
      if (monitorRef.current) {
        // eslint-disable-next-line
        monitorRef.current.close();
      }
      if (alertRef.current) {
        // eslint-disable-next-line
        alertRef.current.close();
      }
    };
  }, [currentPage, itemsPerPage]);

  const refreshSocketMonitor = () => {
    if (monitorRef.current) {
      monitorRef.current.close();
    }

    connectWebSocketMonitoring({
      socket: monitorRef,
      page: currentPage,
      itemsPerPage: itemsPerPage,
      setMonitoring: setMonitoring,
    });
  };
  const refreshSocketAlert = () => {
    if (alertRef.current) {
      alertRef.current.close();
    }

    connectWebSocketAlert({
      socket: alertRef,
      setAlerts: setAlerts,
    });
  };

  return (
    <MainDashboardContext.Provider
      value={{
        currentPage,
        itemsPerPage,
        setCurrentPage,
        setItemsPerPage,
        monitoring,
        monitoringLoading,
        alerts,
        refreshSocketMonitor,
        refreshSocketAlert,
        pageLoading,
      }}
    >
      {children}
    </MainDashboardContext.Provider>
  );
}

export { MainDashboardProvider, useMainDashboardContext };
