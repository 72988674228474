import React, { useEffect } from "react";
import "../../style/alertModal.css";
import FormattedDateTime from "../FormattedDateTime";
import { postAlertRead } from "../../api/monitoring";

function AlertsCard({ data }) {
  useEffect(() => {
    if (!data.abn_acknowledged) {
      console.log("AlertsCard ===== 2222");
      console.log(data.alert_id);
      postAlertRead({ alertId: data.alert_id });
    }
  }, [data]);

  return (
    <div className="alertContainer">
      <div
        className="alertInfo"
        style={{
          color: data?.abn_acknowledged ? "#000" : "#FF5050",
        }}
      >
        <span
          style={{
            fontSize: "15px",
            fontWeight: data?.abn_acknowledged ? 400 : 700,
          }}
        >
          {data?.alert_title}
        </span>
        <span className="alertInfo-description">
          {data?.abn_additional_data}
        </span>
      </div>

      <FormattedDateTime
        dateTimeString={data?.abn_alert_time}
        color={"#909090"}
      />
    </div>
  );
}

export default AlertsCard;
