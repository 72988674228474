import axios from "./axios";

const getMonitoring = async ({ page, itemsPerPage }) => {
  const data = await axios.get("/api/monitoring", {
    params: {
      page,
      items_per_page: itemsPerPage,
    },
    headers: {
      Accept: "application/json",
    },
  });

  return data;
};

const getMonitoringSensor = async ({ sensorId }) => {
  const data = await axios.get(`/api/monitoring/sensor/${sensorId}`);

  return data.data;
};

const getMonitoringSensorAlerts = async ({
  sensorId,
  page = 1,
  itemsPerPage = 10,
}) => {
  const data = await axios.get(`/api/monitoring/sensor/${sensorId}/alerts`, {
    params: {
      page,
      items_per_page: itemsPerPage,
    },
  });

  return data;
};

const getAlert = async () => {
  const data = axios.get(`/api/alerts`, {
    headers: {
      Accept: "application/json",
    },
  });

  return data;
};

const postAlertRead = async ({ alertId }) => {
  const data = axios.post(`/api/alerts/${alertId}/read`);

  return data;
};

export {
  getMonitoring,
  getMonitoringSensor,
  getMonitoringSensorAlerts,
  postAlertRead,
  getAlert,
};
