import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import closeIcon from "../../assets/close.svg";
import "../../style/dashboardModal.css";
import "../../style/modalLayout.css";
import useUserStatus from "../../hooks/useUserStatus";
import heartIcon from "../../assets/detail_heart.svg";
import breathIcon from "../../assets/detail_breath.svg";
import ChartCard from "../card/ChartCard";
import FormattedDateTime from "../FormattedDateTime";
import { useMainDashboardContext } from "../../context/MainDashboardProvider";
import {
  getMonitoringSensor,
  getMonitoringSensorAlerts,
} from "../../api/monitoring";
import { FadeLoader } from "react-spinners";

Modal.setAppElement("#root");

const DashboardModal = ({ onClose, onAlertClick, selectedRoom }) => {
  const [roomData, setRoomData] = useState(null);
  const [roomAlerts, setRoomAlerts] = useState(null);
  const [loading, setLoading] = useState(true);

  const { monitoring, alerts } = useMainDashboardContext();
  const { getStatusColor, getStatusIcon, getStatus } = useUserStatus();

  useEffect(() => {
    if (selectedRoom) {
      document.body.classList.add("modal-open");
      (async () => {
        try {
          const sensorData = await getMonitoringSensor({
            sensorId: selectedRoom?.roomDatas?.sensor_id,
          });
          const sensorAlerts = await getMonitoringSensorAlerts({
            sensorId: selectedRoom?.roomDatas?.sensor_id,
          });

          setRoomData(sensorData?.data);
          setRoomAlerts(sensorAlerts?.data);
        } catch (error) {
          console.error("Error DashboardModal getData", error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [selectedRoom, monitoring, alerts]);

  const customStyles = {
    content: {
      backgroundColor: "#fff",
      width: "460px",
      height: "500px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: 0,
      padding: 0,
      border: "1px solid #131b45",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  if (!selectedRoom) return null;

  const handleCardClick = () => {
    onAlertClick(roomData);
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Modal"
      style={customStyles}
    >
      <div>
        {/* 헤더 */}
        <div className="modalHeader">
          <span>세대별 실시간 모니터링</span>
          <img
            src={closeIcon}
            alt="closeIcon"
            className="close-Icon"
            onClick={onClose}
          />
        </div>
        {/* 메인 */}
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "450px",
            }}
          >
            <FadeLoader color="#4D607B" />
          </div>
        ) : (
          <div className="modalMain">
            <span className="mainAddress">{roomData?.address}</span>
            <div
              className={`mainCard ${getStatusColor(roomData?.user_status)}`}
            >
              <img
                src={getStatusIcon(roomData?.user_status)}
                alt="statusIcon"
                className="modalLogo"
              />

              <span
                className={`modalUserStatus ${getStatusColor(
                  roomData?.user_status
                )}`}
              >
                {getStatus(roomData?.user_status)}
              </span>
            </div>
            <div className="modalStatus">
              <span className="title">실시간 생체 정보</span>
              <div className="statusInfo">
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "40px",
                  }}
                >
                  <div className="statusInfoDiv">
                    <img
                      src={heartIcon}
                      alt="heartIcon"
                      className="modalIcon"
                    />
                    <span>심박수</span>
                  </div>
                  <span className="infoSpan">
                    <span className="modalIcon-number">
                      {roomData?.heart_rate}
                    </span>
                    <span className="modalIcon-title">회</span>
                  </span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="statusInfoDiv">
                    <img
                      src={breathIcon}
                      alt="breathIcon"
                      className="modalIcon"
                    />
                    <span>호흡수</span>
                  </div>
                  <span className="infoSpan">
                    <span className="modalIcon-number">
                      {roomData?.respiration}
                    </span>
                    <span className="modalIcon-title">회</span>
                  </span>
                </div>
              </div>
            </div>
            {roomData && (
              <ChartCard
                heart={roomData.avg_heart_rate_lastdays}
                respiration={roomData.avg_respiration_lastdays}
              />
            )}
            <div
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              {roomAlerts.alert_unread_exists ? (
                <div
                  className="modal-alert-info"
                  onClick={() => handleCardClick()}
                >
                  <div className="modal-alert-info-left">
                    <span className="modal-alert-text">
                      {roomAlerts?.data[0]?.alert_title}
                    </span>
                    <span className="modal-alert-description">
                      {roomAlerts?.data[0]?.abn_additional_data}
                    </span>
                  </div>
                  <div className="modal-alert-info-right">
                    <FormattedDateTime
                      dateTimeString={roomAlerts?.data[0]?.abn_alert_time}
                      color={"#fff"}
                    />
                  </div>
                </div>
              ) : roomAlerts?.alert_exists ? (
                <div
                  className="modal-button"
                  style={{ borderColor: "#000" }}
                  onClick={() => handleCardClick()}
                >
                  <span
                    style={{ color: "#000" }}
                    className="modal-button-title"
                  >
                    경고 알림 기록
                  </span>
                </div>
              ) : (
                <div className="modal-button" style={{ borderColor: "#aaa" }}>
                  <span
                    style={{ color: "#aaa" }}
                    className="modal-button-title"
                  >
                    경고 알림 없음
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DashboardModal;
