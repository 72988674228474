import React, { useState } from "react";
import "../style/app.css";
import Pagination from "../components/Pagination";
import DashboardCard from "../components/card/DashboardCard";
import Layout from "../components/Layout";
import DashboardModal from "../components/modal/DashboardModal";
import AlertsModal from "../components/modal/AlertsModal";
import { useMainDashboardContext } from "../context/MainDashboardProvider";
import { FadeLoader } from "react-spinners";
const ITEMS_PER_PAGE_OPTIONS = [30, 60, 90, 120];

// mainboard
// mainboardWebsocket
// mainboardSocketClose
// alert
// alertSocket
// alertSocketClose

const MainDashboard = () => {
  const {
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
    monitoring,
    alerts,
    monitoringLoading,
    pageLoading,
  } = useMainDashboardContext();

  const [dashboardModalVisible, setDashboardModalVisible] = useState(false);
  const [alertsModalVisible, setAlertsModalVisible] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [alertRoom, setAlertRoom] = useState(null);

  const handleCardClick = (room) => {
    setSelectedRoom({ roomDatas: room });
    setDashboardModalVisible(true);
  };

  const handleCloseDashboardModal = () => {
    setSelectedRoom(null);
    setDashboardModalVisible(false);
  };

  const handleOpenAlertsModal = (alerts) => {
    setAlertRoom(alerts);
    setDashboardModalVisible(false);
    setAlertsModalVisible(true);
  };

  const handleCloseAlertsModal = () => {
    setAlertRoom(null);
    setAlertsModalVisible(false);
  };
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const getAlertsForRoom = (sensor_id) => {
    return alerts?.data?.find(
      (item) => item.sensor_id === sensor_id && !item.abn_acknowledged
    );
  };

  if (monitoringLoading) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f0f5fb",
        }}
      >
        <FadeLoader color="#4D607B" />
      </div>
    );
  }

  return (
    <Layout onHeaderAlertClick={handleOpenAlertsModal}>
      {pageLoading ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FadeLoader color="#4D607B" />
        </div>
      ) : (
        <div>
          <div className="dashboard_header">
            <div className="controls">
              <div className="roomTitle">전체 모니터링</div>
              <div
                style={{
                  alignItems: "end",
                  display: "flex",
                }}
              >
                <span className="itemsPerPage">페이지당 보기</span>
                <select
                  className="itemsPerPage-number"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  {ITEMS_PER_PAGE_OPTIONS.map((option) => (
                    <option key={option} value={option}>
                      {option}개
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="dashboard">
            {monitoring?.data?.map((room, index) => {
              return (
                <DashboardCard
                  key={index}
                  data={room}
                  alerts={getAlertsForRoom(room?.sensor_id)}
                  onClick={() => {
                    handleCardClick(room);
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "400px" }}>
          <Pagination
            currentPage={currentPage}
            totalPages={monitoring?.total_pages}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>

      {dashboardModalVisible && (
        <DashboardModal
          onClose={handleCloseDashboardModal}
          onAlertClick={handleOpenAlertsModal}
          selectedRoom={selectedRoom}
        />
      )}

      {alertsModalVisible && (
        <AlertsModal onClose={handleCloseAlertsModal} alertRoom={alertRoom} />
      )}
    </Layout>
  );
};

export default MainDashboard;
