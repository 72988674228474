import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Login from "./page/Login";
import PrivateRoute from "./routes/PrivateRoute";
import { useAuth } from "./context/AuthContext";
import MainPage from "./page/MainPage";

function App() {
  const queryClient = new QueryClient();

  const { isAuthenticated } = useAuth();

  // const webSocket = useRef(null);

  // connectWebSocketMonitoring(webSocket);

  return (
    <div style={{ fontFamily: "Roboto, sans-serif" }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/*"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <MainPage />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
