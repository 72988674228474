import React from "react";

const FormattedDateTime = ({ dateTimeString, color }) => {
  const formatDateTime = (dateTimeString) => {
    const datePart = dateTimeString.split(" ")[0].split("-").join(".");
    const timePart = dateTimeString.split(" ")[1];
    return `${datePart} ${timePart}`;
  };

  return (
    <span style={{ fontSize: "14px", fontWeight: 400, color: color }}>
      {dateTimeString && formatDateTime(dateTimeString)}
    </span>
  );
};

export default FormattedDateTime;
