import axios from "axios";

const HOST_URL = "https://smarthome-dashboard-back.weplto.co.kr";
// const HOST_URL = "http://52.78.193.192:8000";

const instance = axios.create({
  baseURL: HOST_URL,
  // 여기에 공통 헤더나 설정 추가
});

export default instance;
