import React, { useState } from "react";
import "../style/pagination.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const [currentPageRange, setCurrentPageRange] = useState(1);
  const maxPageNumbersToShow = 10;

  const startPage = (currentPageRange - 1) * maxPageNumbersToShow + 1;
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const handlePrevClick = () => {
    if (currentPage <= startPage && currentPage !== 1) {
      onPageChange((prev) => prev - 1);
      setCurrentPageRange((prev) => prev - 1);
    } else if (currentPage > 1) {
      onPageChange((prev) => prev - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < endPage) {
      onPageChange((prev) => prev + 1);
    } else if (endPage < totalPages) {
      onPageChange((prev) => prev + 1);
      setCurrentPageRange((prev) => prev + 1);
    }
  };

  return (
    <div className="pagination">
      <button
        className="pagination-arrow"
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      >
        &#9664;
      </button>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        {pageNumbers.map((number) => (
          <button
            key={number}
            className={`pagination-number ${
              currentPage === number ? "active" : ""
            }`}
            onClick={() => onPageChange(number)}
          >
            {number}
          </button>
        ))}
      </div>
      <button
        className="pagination-arrow"
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        &#9654;
      </button>
    </div>
  );
};

export default Pagination;
