import React, { useState } from "react";
import "../style/app.css";
import "../style/login.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { localKeys } from "../constants/keys";

const selectList = [
  { value: "master_admin", name: "master admin" },
  { value: "master_admin2", name: "master admin2" },
  { value: "master_admin3", name: "master admin3" },
];
const Login = () => {
  const navigate = useNavigate();

  const { login } = useAuth();
  const [selected, setSelected] = useState("master_admin");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  const handleLogin = () => {
    if (password.length >= 8 && localKeys.passwordKey[selected] === password) {
      login(selected);
      navigate("/", { replace: true });
    } else {
      setErrorMessage("로그인에 실패하였습니다");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="loginMain">
      <div className="loginBox">
        <span style={{ color: "#0B3A8A", fontSize: 24, fontWeight: 700 }}>
          입주세대 헬스케어 대시보드
        </span>
        <select className="select" onChange={handleSelect} value={selected}>
          {selectList.map((item) => {
            return (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            );
          })}
        </select>
        <input
          className="input"
          type="password"
          maxLength="8"
          placeholder="비밀번호를 입력하세요"
          value={password}
          onKeyDown={handleKeyDown}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button
          className="button"
          onClick={handleLogin}
          //   disabled={password.length !== 8}
          style={{
            backgroundColor: password.length === 8 ? "#007AF5" : "#bed7fc",
          }}
        >
          로그인
        </button>

        {errorMessage && (
          <p style={{ color: "#FF5050", fontSize: 12, fontWeight: 500 }}>
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;
